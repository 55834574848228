<template>
  <div class="flex">
    <el-carousel class="left-part" :autoplay="false" :style="index === 1 ? 'float: right' : 'float: left'" arrow="never">
      <el-carousel-item v-for="(s, index) in [videos[0]]" :key="index">
        <VideoImage @click="router.push('/video/' + s.id)" :width="450" class="v-s-i" style="" :src="s.coverImgUrl" />
        <p class="title">{{s.name}}</p>
        <p class="detail">
          <span><span class="tag" :key="c.id" v-for="c in (s.typeArr || s.categories || []).slice(0,2)">{{typeof c === 'string' ? c : c.name}}</span></span>
          <!-- <span class="issue" v-if="s.issue">{{s.issue}}集</span> -->
        </p>
      </el-carousel-item>
    </el-carousel>
    <div class="right-part" :style="index === 1 ? 'float: left' : 'float: right'">
      <div class="flex flex-right-hand" >
        <el-row ref="scrollArea" :gutter="20">
          <el-col v-for="(s, index) in videos.slice(isMobile ? 0 : 1, 9)" :key="index" class="col" :span="6">
            <NewVideoItem :width="150" :video="s" />
          </el-col>
        </el-row>
        <div class="right-hander" @click="goRight" v-if="isMobile">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getOrder } from "@api";
import NewVideoItem from '@c/NewVideoItem.vue'
import VideoImage from '@c/VideoImage.vue'
import { useRouter } from 'vue-router';
import { ref } from '@vue/reactivity';
export default {
  name: "videoSwipper",
  components: {
    NewVideoItem,
    VideoImage
  },
  props: {
    videos: Array,
    isMobile: Boolean,
    index: Number
  },
  setup () {
    const scrollArea = ref(null)
    return {
      router: useRouter(),
      scrollArea,
      goRight() {
        scrollArea.value.$el.scrollLeft = scrollArea.value.$el.scrollLeft + scrollArea.value.$el.offsetWidth
      }
    }
  }
};
</script>


<style scoped lang="stylus">
.flex {
  display: block;
  overflow: hidden;
}
.v-s-i {
  cursor: pointer;
  height: 169px
}
.left-part {
  width: 37.6%;
  .title {
    margin-top 8px
  }
}
.left-part ::v-deep(.el-carousel__container) {
  height: 250px;
}
.right-part {
  width: 59.7%;
}
.col,.right-hander {
  margin-bottom: 30px;
}
.title {
  font-size: 16px;
  color: #262626;
  font-family: '微软雅黑';
  height: 20px;
  line-height: 20px;
}
.detail {
  font-size: 13px;
  font-family: '微软雅黑';
  color: #5C5C5C;
  display: flex;
  justify-content: space-between;
}
.issue {
  color: #262626;
}
.title,.detail {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tag {
  margin-right: 10px;
}

@media screen and (max-width: 1050px) {

}

@media screen and (max-width: 770px) {
  .v-s-i {
    cursor: pointer;
    height: 140px
  }
}
@media screen and (max-width: 767px) {
  .left-part {
    display: none;
  }
  .right-part {
    width: 100%;
  }
  .el-row {
    flex-wrap: nowrap;
    overflow auto;
    width: calc(100vw - 40px);
    scroll-behavior: smooth;
    & .el-col {
      width: 210px;
      max-width: 210px;
      flex: auto;
      flex-shrink: 0;
    }
  }
  .flex-right-hand {
    display flex
    justify-content: space-between;
    & .right-hander {
      width 30px
      background: rgba(0,0,0,.2);
      display flex
      align-items center
      justify-content center
      font-size 20px
      color #fff
      font-weight bold
    }
  }
}
</style>
