<template>
  <p class="title">
    <span>{{title}}</span>
    <span v-if="more" @click="cid && router.push('/videos/' + cid)" class="right-part"><img class="bi" src="../assets/pic/bi.png" alt=""><img class="more" src="../assets/pic/more.png" alt=""></span>
  </p>
</template>
<script>
import { useRouter } from 'vue-router'
export default {
  name: 'itemTitle',
  props: {
    title: String,
    cid: Number,
    more: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    return {
      router: useRouter()
    }
  }
}
</script>
<style scoped>
.title {
  font-size: 23px;
  font-family: '微软雅黑';
  font-weight: bold;
  color: #361905;
  height: 23px;
  line-height: 23px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 4px;
}
.bi {
  height: 33px;
  margin-right: 4px;
}
.more {
  height: 17px;
}
.right-part {
  cursor: pointer;
}
</style>